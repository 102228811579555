/** @format */

import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Grid,
	GridItem,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import {fonts} from '../../styles/js/fonts';
import {textStyles} from '../../styles/js/textStyles';
import {decode} from 'html-entities';

type TitleCategoryProps = {
	text: string;
	domainFontFamily?: string; // Optional if not always passed.
	subdomain?: string
};

type TitleProps = {
	text: string;
	heroComponent?: boolean;
	domainFontFamily?: string;
	titleDomainFontFamily?: string;
	subdomain?: string
};

type SubtitleProps = {
	isLastOne: boolean;
	text: string;
	heroComponent?: boolean;
	domainFontFamily?: string;
	subtitleDomainFontFamily?: string;
	subdomain?: string
};

type ButtonType = {
	title: string;
	url: string;
	style: string;
	colorScheme?: string;
};

type ContainerChildProps = {
	titleCategory?: string;
	title?: string;
	heroComponent?: boolean;
	domainFontFamily?: string;
	titleDomainFontFamily?: string;
	subtitles?: string[];
	subtitleDomainFontFamily?: string;
	buttons?: ButtonType[];
	padding?: any;
	subdomain?: string;
};

type DefaultComponentProps = ContainerChildProps & {
	padding?: any;
};

const TitleCategory: React.FC<TitleCategoryProps> = ({
	text,
	domainFontFamily,
	subdomain
}) => {
	// console.log('category - subdomain - ',subdomain);
	return (
		<Text
			{...textStyles.displaymd}
			pb={{base: '16px'}}
			fontFamily={domainFontFamily}>
			{decode(text)}
		</Text>
	);
};

const Title: React.FC<TitleProps> = ({
	text,
	heroComponent,
	domainFontFamily,
	titleDomainFontFamily,
	subdomain
}) => {
	// console.log('title - subdomain - ',subdomain);
	let textStylesDefault = useBreakpointValue(
		{
			base: textStyles.text2Xl,
			md: textStyles.text3Xl,
		},
		{
			ssr: true,
		},
	);

	if (heroComponent)
		textStylesDefault = useBreakpointValue(
			{
				base: subdomain === 'thegregory' ? textStyles.text2Xl : textStyles.text3Xl,
				md: textStyles.text5Xl,
				lg: textStyles.text7Xl,
			},
			{
				ssr: true,
				fallback: 'lg',
			},
		);

	return (
		<Text
			{...textStylesDefault}
			// fontSize={fontSize}
			lineHeight={subdomain === 'thegregory' ? '100%' : ''}
			letterSpacing={subdomain === 'thegregory' ? '-1.8px' : '-0.4px'}
			pb={{base: '32px'}}
			fontFamily={
				titleDomainFontFamily ? titleDomainFontFamily : domainFontFamily
			}>
			{decode(text)}
		</Text>
	);
};

const Subtitle: React.FC<SubtitleProps> = ({
	isLastOne,
	text,
	heroComponent,
	domainFontFamily,
	subtitleDomainFontFamily,
	subdomain = ''
}) => {
	let fontSize = {base: '16px'};
	let textStylesDefault = { ...textStyles.textsm };

	if (heroComponent) {
		fontSize = {base: '40px'};
		textStylesDefault = { ...textStyles.text2Xl };

		if (subdomain === 'thegregory') {
			let sizes = { base: '30px', md: '40px' };
			fontSize = sizes;
			textStylesDefault = textStyles.textxl;
			textStylesDefault.lineHeight = '1.1';
		}
	}

	return (
		<Text
			{...textStylesDefault}
			fontSize={fontSize}
			pb={{base: isLastOne ? '0px' : '24px'}}
			fontFamily={
				subtitleDomainFontFamily ? subtitleDomainFontFamily : domainFontFamily
			}>
			{decode(text)}
		</Text>
	);
};

const ContainerChild: React.FC<ContainerChildProps> = ({
	titleCategory,
	title,
	heroComponent,
	domainFontFamily,
	titleDomainFontFamily,
	subtitles,
	subtitleDomainFontFamily,
	buttons,
	padding,
	subdomain
}) => {

	return (
		<Grid padding={padding}>
			<GridItem w={{base: '100%'}} className='textComponent-grid'>
				{titleCategory ? (
					<TitleCategory
						text={titleCategory}
						domainFontFamily={subdomain === 'thegregory' ? fonts.GillSansMedium : fonts.copernicusGalaxyBook}
						subdomain={subdomain}
					/>
				) : null}
				{title ? (
					<Box sx={{
						textTransform: subdomain === 'thegregory' ? 'uppercase' : '',
						lineHeight: subdomain === 'thegregory' ? '100%' : '',
						'& *': {
							textTransform: subdomain === 'thegregory' ? 'uppercase' : '',
							lineHeight: subdomain === 'thegregory' ? '100%' : '',
						},
						}}>
						<Title
							text={title}
							heroComponent={heroComponent}
							domainFontFamily={domainFontFamily}
							titleDomainFontFamily={titleDomainFontFamily}
							subdomain={subdomain}
						/>
					</Box>
				) : null}
			</GridItem>

			{subtitles && subtitles.length > 0 ? (
				<GridItem pb={{base: buttons && buttons.length ? '40px' : '0px'}}>
					{subtitles.map((subtitle, index) => (
						<Subtitle
							key={index}
							isLastOne={index === subtitles.length - 1}
							heroComponent={heroComponent}
							text={subtitle}
							domainFontFamily={domainFontFamily}
							subtitleDomainFontFamily={subtitleDomainFontFamily}
							subdomain={subdomain}
						/>
					))}
				</GridItem>
			) : null}

			{buttons && buttons.length ? (
				<GridItem>
					<Stack
						direction={{base: 'column', md: 'row'}}
						spacing={6}
						className='text-buttongroup'>
						{buttons.map((button, i) => (
							<Button
								key={`${i}-text-button`}
								size='lg'
								variant={button.style}
								as='a'
								href={button.url}
								colorScheme={button.colorScheme || 'aqua'}>
								{button.title}
							</Button>
						))}
					</Stack>
				</GridItem>
			) : null}
		</Grid>
	);
};

const DefaultComponent: React.FC<DefaultComponentProps> = ({
	titleCategory,
	title,
	subtitles,
	heroComponent = false,
	buttons = [],
	domainFontFamily,
	subtitleDomainFontFamily = '',
	titleDomainFontFamily = '',
	padding,
	subdomain = ''
}) => {
	// console.log('TextComponent DefaultComponent domainFontFamily: ',domainFontFamily);
	if (padding) {
		return (
			<ContainerChild
				titleCategory={titleCategory}
				title={title}
				heroComponent={heroComponent}
				domainFontFamily={domainFontFamily}
				titleDomainFontFamily={titleDomainFontFamily}
				subtitles={subtitles}
				subtitleDomainFontFamily={subtitleDomainFontFamily}
				buttons={buttons}
				padding={padding}
				subdomain={subdomain}
			/>
		);
	}
	return (
		<Container position='relative' maxW='container.xl' mx='auto'>
			<ContainerChild
				titleCategory={titleCategory}
				title={title}
				heroComponent={heroComponent}
				domainFontFamily={domainFontFamily}
				titleDomainFontFamily={titleDomainFontFamily}
				subtitles={subtitles}
				subtitleDomainFontFamily={subtitleDomainFontFamily}
				buttons={buttons}
				subdomain={subdomain}
			/>
		</Container>
	);
};

export default DefaultComponent;
